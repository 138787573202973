import React from "react"
import { Link } from "gatsby"

import dropDownArrow from "../images/drop-down-icon.svg"
import downloadIcon from "../images/icons/download-icon.svg"

class Footer extends React.Component {
    render() {
        return (
            <div class="drawer">

                <div class="nav-mobile">
                    <Link to="/">Shul</Link>
                    {/* <!-- <a href="../pages/index.html">Sh<span>u</span>l</a>    --> */}
                    <span class="shul-u">_</span>
                </div>

                <div class="nav-mobile">
                    <Link to="/ekonk">Ekonk</Link>
                </div>

                <div class="nav-mobile">
                    <Link to="/dico">Dico</Link>
                </div>


                {/* <!-- <div class="divider"></div> --> */}

                <div class="nav-mobile">
                    <a id="services-mobile">Services </a>
                    <img class="drop-down-arrow" src={dropDownArrow}></img>
                </div>

                <div class="drop-down">
                    <div class="divider"></div>
                    <Link to="/design">Design</Link>
                    {/* <!-- <div class="divider"></div> --> */}
                    <Link to="/engineering">Engineering</Link>
                </div>

                <div class="nav-mobile">
                    <div class="download-drawer">
                        <a href="https://drive.google.com/file/d/1Ozp9gcn0ISMeD_JydXCIw1prsYZH6-wS/view?usp=sharing" target="_blank"><img src={downloadIcon} /></a>
                    </div>

                </div>


            </div>
        )
    }
}

export default Footer