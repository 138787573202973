import React from "react"

import shulSide from "../images/shul side.png"

import fbGold from "../images/fb-gold.png"
import ytGold from "../images/yt-gold.png"
import igGold from "../images/ig-gold.png"
import emailGold from "../images/email-gold.png"

import footerBG from "../images/vazirani-footer.png"
import { transform } from "framer-motion"

class Footer extends React.Component {
    render(){
        return(
            // <div class="footer" style={{backgroundImage: `url(${footerBG})`}}>
            //     <div class="contact">
            //         <div class="email">
            //             <div class="title">For Enquiries & Careers:</div>
            //             <div class="email-id">connect@vazirani-automotive.com</div>
            //         </div>
            //         <div class="shul-footer-graphic">
            //             <img src={shulSide}></img>
            //             <div class="social-media">
            //                 <div>
            //                     <a href="https://www.facebook.com/VaziraniAutomotive" target="_blank"><img src={fbGold}></img></a>
            //                 </div>
            //                 <div>
            //                     <a href="https://www.youtube.com/channel/UCgDiLWpI2XtVCKg6rFBaOJw" target="_blank"><img src={ytGold}></img></a>
            //                 </div>
            //                 <div>
            //                     <a href="https://www.instagram.com/vazirani_automotive/" target="_blank"><img src={igGold}></img></a>
            //                 </div>
            //             </div>
            //         </div>
                    
            //     </div>
            //     <div class="legal">
            //         <p>Copyright © 2021 Vazirani Automotive Pvt. Ltd. All rights reserved.</p>
            //     </div>
                  
            // </div>

            <div class="footer-2">
                <div class="legal">
                    <p>Copyright © 2024 Vazirani Automotive Pvt. Ltd. All rights reserved.</p>
                </div>
                <div class="social-media">
                            <div>
                                 <a href="mailto:connect@vazirani-automotive.com" target="_blank"><img src={emailGold}></img></a>
                             </div>
                           <div>
                                 <a href="https://www.facebook.com/VaziraniAutomotive" target="_blank"><img src={fbGold}></img></a>
                             </div>
                             <div>
                                 <a href="https://www.youtube.com/channel/UCgDiLWpI2XtVCKg6rFBaOJw" target="_blank"><img src={ytGold}></img></a>
                             </div>
                             <div>
                                 <a href="https://www.instagram.com/vazirani_automotive/" target="_blank"><img src={igGold}></img></a>
                             </div>
                         </div>

            </div>
        )
    }
}

export default Footer